import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { IconComponent } from "@zippeditoolsjs/zippedi-icons";

function SubCategoryRow(props) {
  const { subCategory, columns, columnWidths } = props;

  return (
    <>
      <Grid container width='100%' py='1.5em' wrap='nowrap'>
        <Grid container item xs justifyContent='flex-end' overflow='auto'>
          <p>{subCategory.cat_1}</p>
        </Grid>
        {columns?.map((column, index) => (
          <Grid container item key={index} justifyContent='flex-end' width={columnWidths[column]} px='1.1em'>
            {subCategory[column]}
          </Grid>
        ))}
      </Grid>
      <Divider />
    </>
  );
}

function CategoryRow(props) {
  const { category, columns, columnWidths } = props;
  const [openSubCategory, setOpenSubCategory] = useState(false);

  return (
    <>
      <Grid container width='100%' py='1em' wrap='nowrap'>
        <Grid container item width='8%' justifyContent='flex-end'>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenSubCategory(!openSubCategory)}
          >
            {openSubCategory ?
              <IconComponent
                iconName={'chevron-up-outline'}
                style={{ fontSize: '25px' }}
              />
              :
              <IconComponent
                iconName={'chevron-down-outline'}
                style={{ fontSize: '25px' }}
              />
            }
          </IconButton>
        </Grid>
        <Grid container item xs justifyContent='center' alignItems='center' overflow='auto'>
          <p>{category.cat_0}</p>
        </Grid>
        {columns?.map((column, index) => (
          <Grid container item key={index} justifyContent='flex-end' alignItems='center' width={columnWidths[column]} px='1.1em'>
            {category[column]}
          </Grid>
        ))}
      </Grid>
      <Collapse in={openSubCategory} timeout="auto" unmountOnExit>
        <Divider />
        {category.cat_1.map((subCategory, index) => (
          <SubCategoryRow key={index} subCategory={subCategory} columns={columns} columnWidths={columnWidths} />
        ))}
      </Collapse>
      <Divider />
    </>
  )
}

function StoreRow(props) {
  const { row, columns, columnWidths, stores } = props;
  const [openCategory, setOpenCategory] = useState(false);

  const store = stores?.find(store => store.store_code === row?.store);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCategory(!openCategory)}
          >
            {openCategory ?
              <IconComponent
                iconName={'chevron-up-outline'}
                style={{ fontSize: '25px' }}
              />
              :
              <IconComponent
                iconName={'chevron-down-outline'}
                style={{ fontSize: '25px' }}
              />
            }
          </IconButton>
        </TableCell>
        <TableCell>{store?.parsed_name}</TableCell>
        {columns?.map((column, index) => (
          <TableCell key={index} align='right'>{row[column]}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} sx={{ padding: 0 }}>
          <Collapse in={openCategory} timeout="auto" unmountOnExit>
            {row.categories.map((category, index) => (
              <CategoryRow key={index} category={category} columns={columns} columnWidths={columnWidths} />
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function RowGroupedTable(props) {
  const { data, stores } = props;
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState({});

  // Lifecycle methods

  // Calculate the width of the columns so they can be used in CategoryRow
  useEffect(() => {
    const table = tableRef.current;
    if (!table) return null; // Return null if the table is not found
    data?.columns?.forEach((column, index) => {
      const cell = table.querySelector(`tr:first-child > td:nth-child(${index + 3})`); // +3 because the first two columns are the expand button and the store name
      if (cell) {
        const cellWidth = cell.getBoundingClientRect().width;
        setColumnWidths(prevState => ({ ...prevState, [column]: cellWidth }));
      }
    });
  }, [data]);

  return (
    <TableContainer sx={{ maxHeight: '100%' }}>
      <Table stickyHeader ref={tableRef}>
        <TableHead>
          <TableRow
            sx={{
              "& th": {
                color: "rgba(96, 96, 96)",
                backgroundColor: "white"
              }
            }}
          >
            <TableCell />
            <TableCell sx={{ width: '14em' }} />
            {data?.columns?.map((column, index) => (
              <TableCell key={index} sx={{ minWidth: '7em' }}>{t(`cws_app.dashboards.${column}`, column)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rows?.map((row, index) => (
            <StoreRow
              key={index}
              row={row}
              columns={data?.columns}
              columnWidths={columnWidths}
              stores={stores}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
